<template>
  <el-dialog title="区域" :visible.sync="dialogVisible" width="400px">
    <div>
      <el-tree
        ref="tree"
        :data="data"
        :node-key="key1"
        :props="{
          label: 'areaName',
          children: 'childrenAreas',
          isLeaf: 'leaf',
        }"
        :load="loadNode1"
        :check-strictly="true"
        lazy
        @node-click="handleNodeClick"
        show-checkbox
      ></el-tree>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="sureButton">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      curChecked: [],
      typeChangeFlag: false,
      dialogVisible: false,
      treeType: "1",
      data: [
        {
          label: "一级 1",
          children: [
            {
              label: "二级 1-1",
              children: [
                {
                  label: "三级 1-1-1",
                },
              ],
            },
          ],
        },
        {
          label: "一级 2",
          children: [
            {
              label: "二级 2-1",
              children: [
                {
                  label: "三级 2-1-1",
                },
              ],
            },
            {
              label: "二级 2-2",
              children: [
                {
                  label: "三级 2-2-1",
                },
              ],
            },
          ],
        },
        {
          label: "一级 3",
          children: [
            {
              label: "二级 3-1",
              children: [
                {
                  label: "三级 3-1-1",
                },
              ],
            },
            {
              label: "二级 3-2",
              children: [
                {
                  label: "三级 3-2-1",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  props: {
    //   treeData: {
    //      default: function () {
    //          return []
    //      }
    //  },
    defaultProps: {
      default: function () {
        return {
          label: "areaName",
          children: "childrenAreas",
          isLeaf: "leaf",
        };
      },
    },
    key1: {
      default: "areaId",
    },
    selectParentList: {
      default: function () {
        return [];
      },
    },
  },
  methods: {
    // setKeys (arr) {
    //   this.curChecked = arr;
    //   this.$refs.tree.setCheckedKeys(arr);
    // },
    setChangeFlag() {
      this.typeChangeFlag = true;
    },
    setTreeType(type) {
      this.treeType = type;
      // this.setKeys([]);
    },
    setIsleaf(data) {
      data.forEach((v) => {
        if (v.childrenAreas && v.childrenAreas.length) {
          //  v.disabled = true;
          this.setIsleaf(v.childrenAreas);
        } else {
          v.leaf = true;
        }
      });
      return data;
    },
    setIsDeabled(data) {
      data.forEach((v) => {
        // v.disabled = true;
        if (v.childrenAreas && v.childrenAreas.length) {
          this.setIsDeabled(v.childrenAreas);
        }
      });
      return data;
    },
    loadNode1(node, resolve) {
      if (node.data.childrenAreas && node.data.childrenAreas.length) {
        resolve(node.data.childrenAreas);
        return;
      }
      if (node.level == 0) {
        this.getArea(node, resolve);
      } else {
        //  node.checked = false;
        //  node.data.disabled = true;
        this.getChildren(node, resolve);
      }
      // else {
      //      resolve([]);
      // }
    },
    getArea(node, resolve) {
      let url = "/acb/2.0/systems/loginUser/initAreaList";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          data.disabled = true;
          this.setTreeType(data.depth);
          if (resolve) {
            if (data.depth == 3) {
              resolve(data.areaList);
            } else {
              resolve(this.setIsDeabled(data.areaList));
            }
          } else {
            if (data.depth == 3) {
              this.data = data.areaList;
            } else {
              this.data = this.setIsDeabled(data.areaList);
            }
          }
        } else {
          if (resolve) {
            resolve([]);
          }
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    // 过滤掉被选中的节点
    filterNode(arr, aim) {
      return arr.filter((v) => {
        return v != aim;
      });
    },
    getChildren(node, resolve) {
      let url = "/acb/2.0/systems/loginUser/getChildrenArea";
      this.$axios
        .get(url, {
          data: { areaId: node.data.areaId },
        })
        .then((res) => {
          if (res.state == 0) {
            resolve(this.setIsleaf(res.value));
            // if (Object.keys(this.selectParentList).length) {
            //     this.setKeys([...this.filterNode(this.curChecked, node.data.areaId), ...this.selectParentList[node.data.areaId]]);
            // }
            // this.treeData = this.sortArea(res.value);
          } else {
            resolve([]);
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    handleNodeClick() {},
    sureButton() {
      this.$emit("treeSure", this.$refs.tree.getCheckedNodes());
    },
  },
  created() {
    this.getArea();
  },
  mounted() {},
};
</script>
<style scoped>
.dialog-footer {
  text-align: center;
}
</style>
